import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { renderMarkdown } from '~/functions/markdown';
import { useGetFundQuery } from '~/types/types';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    fundId: {
      type: Number,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var headers = computed(function () {
      return [{
        value: 'name',
        sortable: true
      }, {
        value: 'minimum'
      }, {
        value: 'recommended'
      }, {
        value: 'full'
      }];
    });
    var _useGetFundQuery = useGetFundQuery({
        id: props.fundId || ''
      }),
      fundResult = _useGetFundQuery.result;
    var fund = computed(function () {
      var _fundResult$value;
      return (_fundResult$value = fundResult.value) === null || _fundResult$value === void 0 ? void 0 : _fundResult$value.getFund;
    });
    var minimumReportingLevelVentureIds = computed(function () {
      var _fund$value;
      return (fund === null || fund === void 0 ? void 0 : (_fund$value = fund.value) === null || _fund$value === void 0 ? void 0 : _fund$value.minimumReportingLevelVentureIds) || [];
    });
    var recommendedReportingLevelVentureIds = computed(function () {
      var _fund$value2;
      return (fund === null || fund === void 0 ? void 0 : (_fund$value2 = fund.value) === null || _fund$value2 === void 0 ? void 0 : _fund$value2.recommendedReportingLevelVentureIds) || [];
    });
    var fullReportingLevelVentureIds = computed(function () {
      var _fund$value3;
      return (fund === null || fund === void 0 ? void 0 : (_fund$value3 = fund.value) === null || _fund$value3 === void 0 ? void 0 : _fund$value3.fullReportingLevelVentureIds) || [];
    });
    var selectOption = function selectOption(item, selectedOption) {
      // First, clear selections from other categories
      var allOptions = ['minimum', 'recommended', 'full'];
      var otherOptions = allOptions.filter(function (option) {
        return option !== selectedOption;
      });
      var ventureId = item.id;

      // Deselect options in other categories
      otherOptions.forEach(function (option) {
        var optionArray;
        switch (option) {
          case 'minimum':
            optionArray = minimumReportingLevelVentureIds.value;
            break;
          case 'recommended':
            optionArray = recommendedReportingLevelVentureIds.value;
            break;
          case 'full':
            optionArray = fullReportingLevelVentureIds.value;
            break;
        }
        if (optionArray.includes(ventureId)) {
          var _updatedArray = optionArray.filter(function (id) {
            return id !== ventureId;
          });
          emitUpdate(_updatedArray, option);
        }
      });

      // Now, toggle the selected option
      var targetOptionArray;
      switch (selectedOption) {
        case 'minimum':
          targetOptionArray = minimumReportingLevelVentureIds.value;
          break;
        case 'recommended':
          targetOptionArray = recommendedReportingLevelVentureIds.value;
          break;
        case 'full':
          targetOptionArray = fullReportingLevelVentureIds.value;
          break;
      }
      var isIncluded = targetOptionArray.includes(ventureId);
      var updatedArray = isIncluded ? targetOptionArray.filter(function (id) {
        return id !== ventureId;
      }) : [].concat(_toConsumableArray(targetOptionArray), [ventureId]);
      emitUpdate(updatedArray, selectedOption);
    };
    var emitUpdate = function emitUpdate(updatedArray, option) {
      emit('input', {
        array: updatedArray,
        option: option
      });
    };
    return {
      headers: headers,
      selectOption: selectOption,
      minimumReportingLevelVentureIds: minimumReportingLevelVentureIds,
      recommendedReportingLevelVentureIds: recommendedReportingLevelVentureIds,
      fullReportingLevelVentureIds: fullReportingLevelVentureIds,
      renderMarkdown: renderMarkdown
    };
  }
});